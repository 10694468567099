<script setup>
import { ref, inject, computed, onMounted, onBeforeUnmount } from "vue";
import VueRouter from "vue-router";
import { app } from 'o365-modules';
import { InjectionKeys } from 'o365-utils';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

const { pwaStoreKey } = InjectionKeys;

const { eventEmitter } = inject(pwaStoreKey);

const router = VueRouter.useRouter();
const route = VueRouter.useRoute();

const cachedApps = ref([]);

// Access the routes from the router instance
const routes = computed(() => {
    const routes = router.options.routes.filter((route) => route?.meta?.includeInMenu === true);
    const sortedRoutes = routes.sort((a, b) => a.meta?.order - b.meta?.order);

    return sortedRoutes;
});

const getApps = async () => {
    const appStates = await IndexedDBHandler.getApps();

    const cachedAppsTemp = [];

    for (const appState of appStates) {
        const pwaState = await appState.pwaState;
        const isAppInstalled = await appState.isAppInstalled;

        if (!isAppInstalled && !['pwa-home', app.id].includes(appState.id)) {
            continue;
        }

        cachedAppsTemp.push({
            appState,
            pwaState
        });
    }

    cachedApps.value = cachedAppsTemp.sort((a, b) => {
        if (a.appState.id === 'pwa-home') {
            return -1;
        }

        const aValue = a.appState.Title ?? a.appState.id;
        const bValue = b.appState.Title ?? b.appState.id;

        return aValue - bValue;
    });
}

const goTo = (name) => {
    if (name === route.name) {
        return;
    }

    router.push({ name });
}

const handleUninstall = () => {
    window.location = "/nt/pwa/pwa-home";
};

const initializeAsync = async () => {
    eventEmitter.on('appInstalled', getApps);
    eventEmitter.on('appUpdated', getApps);
    eventEmitter.on('appUninstalled', handleUninstall);
    eventEmitter.on('allAppsUninstalled', getApps);
};

onMounted(async () => {
    await initializeAsync();
    await getApps();
});

onBeforeUnmount(async () => {
    eventEmitter.off('appInstalled', getApps);
    eventEmitter.off('appUpdated', getApps);
    eventEmitter.off('appUninstalled', handleUninstall);
    eventEmitter.off('allAppsUninstalled', getApps);
});
</script>

<template>
    <div class="d-flex flex-column bg-light rounded gap-2 p-2">
        <h4 class="m-0">{{ $t('Apps') }}</h4>

        <hr class="m-0">

        <template v-for="cachedApp in cachedApps">
            <component :is="cachedApp.appState.id === app.id ? 'span' : 'a'" :href="`/nt/pwa/${cachedApp.appState.id}`"
                class="w-100 d-flex justify-content-start align-items-center gap-1 rounded-3 no-style app-button" :class="{
                    'app-button-disabled': !cachedApp.pwaState.isAppInstalled, 'app-button-current': cachedApp.appState.id === app.id
                }">
                <div class="flex-shrink-0 p-2">
                    <i style="font-size: 1.5em; font-weight: 500;"
                        :class="cachedApp.appState.icon ?? 'bi bi-question-lg'"></i>
                </div>

                <div class="flex-grow-1">
                    <span style="font-size: 1em; font-weight: 500;">
                        {{ cachedApp.appState.title ?? cachedApp.appState.id }}
                    </span>
                </div>
            </component>

            <template v-if="cachedApp.appState.id === app.id" v-for="NavRoute in routes">
                <div data-bs-dismiss="offcanvas" @click="$event => goTo(NavRoute.name)"
                    class="w-100 small d-flex gap-1 ps-3 rounded-3"
                    style="text-indent:0.5em;color: rgba(70, 130, 180, 1);">
                    <div class="d-flex flex-grow-1 rounded-3 align-items-center page-button" :class="{
                        'page-button-active': NavRoute.name === route.name
                    }">
                        <div class="flex-shrink-0 p-2">
                            <i class="" style="font-size: 1.5em; font-weight: 500;" :class="NavRoute.meta.iconClass"></i>
                        </div>

                        <div class="flex-grow-1">
                            <span style="font-size: 1em; font-weight: 500;">
                                {{ NavRoute.meta?.title ?? NavRoute.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<style scoped>
.app-button {
    cursor: pointer;
    color: rgba(70, 130, 180, 1);
    transition: background-color 200ms ease-in-out;
}

.app-button:not(.app-button-active, .app-button-current, .app-button-disabled):hover {
    background-color: rgba(70, 130, 180, 0.1);
}

.app-button-current {
    cursor: default;
}

.app-button-active {
    background-color: rgba(70, 130, 180, 0.1);
}

.app-button-disabled {
    color: rgb(40%, 40%, 40%);
}

.app-button-disabled,
.app-button-active {
    cursor: default;
}

.page-button {
    cursor: pointer;
    color: rgba(70, 130, 180, 1);
    transition: background-color 200ms ease-in-out;
}

.page-button:not(.page-button-active, .page-button-disabled):hover {
    background-color: rgba(70, 130, 180, 0.2);
}

.page-button-active {
    background-color: rgba(70, 130, 180, 0.2);
}

.page-button-disabled {
    color: rgb(40%, 40%, 40%);
}

.page-button-disabled,
.app-button-active {
    cursor: default;
}
</style>